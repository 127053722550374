@font-face {
  font-family: "Mindstay";
  src: local("Mindstay Demo"),
    url("./fonts/Mindstaydemo-x326V.ttf") format("truetype");
}
@font-face {
  font-family: "Futura";
  src: local("Futura Medium"),
    url("./fonts/futura medium bt.ttf") format("truetype");
}
.App{
  background-color: #F5EEDD;
}
body{
  background-color: #F5EEDD;
}
h1{
  font-family: 'Mindstay', serif;
}

/* Navigation bar and Menu */
.navBar{
  display: flex;
  width: 100%;
  padding: 20px;
  z-index: 30;
}
.navbar-visible {
  display: flex;
    width: 100%;
    padding: 20px;
    z-index: 20;
}
.navbar-hidden {
  display: none;
}
.navTitle{
  display: flex;
  flex: 70%;
  text-decoration: none;
  font-family: 'Mindstay', serif;
  color: #D73B13;
  font-size: 70px;
  z-index: inherit;
}
.navUL{
  display: flex;
  flex: 35%;
  list-style: none;
  z-index: inherit;
}
.navLi{
  margin: 0px 30px 0px 30px;
  font-family: 'Futura'; 
  z-index: inherit;
}
.navLink{
  text-decoration: none;
  font-size: 30px;
  color: black;
transition: ease-in-out 0.3s;
z-index: inherit;
}
.navLink:hover{
  text-decoration: underline;
  text-underline-offset: 5px;
  transition: ease-in-out 0.3s;
}
/* Footer */
footer{
  display: flex;
  margin: 10px;
  flex: 60%;
}
.footerTitle{
  font-family: 'Mindstay';
}
.footerList{
  display: flex;
  list-style: none;
  position: relative;
  color: black;
  margin-left: 1000px;
}
.footerIcons{
  color: black;
}
/* Home Page */
.homeMain{
  display: flex;
}

.heroTitle{
  font-family: 'Futura';
  font-size: 64px;

  text-align: right;
}
.underlinedName{
  font-family: 'Mindstay';
  color: #D73B13;
  font-size: 90px;
  text-decoration: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
  font-style: normal;
}
.redHero{
  font-style: normal;
  font-family: 'Mindstay';
    color: #D73B13;
    font-size: 90px;
}
.homeSummary{
  max-width: 800px;
  margin-left: 50px;
  text-align: right;
  font-family: 'Futura';
  font-size: 24px;
  transform: translateY(-60px);
}
.redText{
color: #D73B13;
text-decoration: underline;
font-style: normal;
text-underline-offset: 3px;
}
.redTextS{
  color: #D73B13;
}
.heroImg{
  width: 50%;
  transform: translateY(-50px);
}
.scrollDown{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #D73B13;
  font-family: 'Mindstay';
  font-size: 32px;
  z-index: 10;
  transform: translateY(-150px);
}
.scrollDown:hover{
color: black;
}
.downHome{
  font-size: large;
}
/* Work Selection */
.workTitle{
  font-size: 96px;
  color: #D73B13;
  font-weight: 400;
  margin-left: 100px;
}
.featuredTitle{
  margin-left: 50px;
  font-size: 64px;
  border-bottom: 2px solid black;
  font-weight: 300;
  width: 90%;
  
}
.workItem{
display: flex;
padding: 25px;
}
.workItemImage{
  width: 40%;
  object-fit: cover;
}
.cardTags{
  display: flex;
  font-family: 'Futura';
  transform: translateY(-30px);
}
.tags{
  border: 1.5px solid black;
  border-radius: 25px;
  margin: 1%;
  padding: 10px 15px 10px 15px;
}
.workItemCopy{
  padding-left: 50px;
  transform: translateY(-60px);
}
.workItemTitle{
  font-size: 64px;
  font-weight: 200;
}
.workItemSummary{
  width: 500px;
  font-family: 'Futura';
  font-size: 24px;
  transform: translateY(-50px);
}
.workItemLink{
  text-decoration: none;
  font-family: 'Mindstay';
  font-size: 30px;
  color: black;
  border: 2px solid #D73B13;
  padding: 10px 15px 10px 15px;
  border-radius: 25px;
 
}
.workItemLink:hover{
color: #D73B13;
}
.workLinkIcon{
  transform: translateY(3px);
  color: #D73B13;
  transition: ease-in-out 0.3s;
}
.workLinkIcon:hover{
  transform: translateX(10px) translateY(2px) ;
  transition: ease-in-out 0.3s;
}
/* About Page */
.aboutMain{
  display: flex;
  margin: 0px 20px 0px 20px;
}
.aboutImg{
  border-radius: 20px;
  margin: 0px 20px 0px 20px;
}
.aboutCopy{
  margin: 0px 40px 0px 40px;
}
.aboutTitle{
  font-size: 96px;
  font-weight: 400;
  color: #D73B13;
  transform: translateY(-80px);
}
.aboutP{
  transform: translateY(-100px);
  font-family: 'Futura';
  font-size: 16px;
}
.resumeDownload{
  font-size: 32px;
  color: black;
  text-decoration: none;
  font-family: 'Mindstay';
  border: 2px solid #D73B13;
  padding: 10px 25px 10px 25px;
  border-radius: 30px;
}
.downloadIcon{
  color: #D73B13;
  transition: ease-in-out 0.3s;
  }

.downloadIcon:hover{
  transform: translateY(5px);
  transition: ease-in-out 0.3s;
  }
.skillTitle{
  font-size: 96px;
  font-weight: 400;
  color: #D73B13;
  margin: 50px 70px 0px 50px;
}
h2{
  font-family: 'Mindstay';
  font-weight: 300;
  font-size: 48px;
  padding-left: 50px;
  transform: translateY(-40px);
}
.skillSection{
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-40px);
}
.skillPill{
  font-family: 'Futura';
  font-size: 24px;
  border: 2px solid black;
  padding: 10px 15px 10px 15px;
  border-radius: 25px;
  margin: 10px;
  
}
/* Contact Page */
.contactEmail{
  font-size: 80px;
  font-family: 'Futura';
  margin-left: 50px;
  color: black;
  transform: translateY(-50px);
  display: flex;
  transition: ease-in-out 0.3s;

}
.contactEmail:hover{
  color: #D73B13;
  transition: ease-in-out 0.3s;
}
.socialList{
  margin-left: 50px;
  font-size: 48px;
  font-family: 'Mindstay';
  font-weight: 400;
}
.socialUL{
  display: flex;
  list-style: none;
  margin-bottom: 70px;
}
.contactLink{
  margin: 10px;
  text-decoration: none;
  font-family: 'Futura';
  color: black;
  font-size: 24px;
  border: 2px solid black;
  border-radius: 25px;
  padding: 10px 20px 10px 20px;
  transition: ease-in-out 0.3s;
}
.contactLink:hover{
  /* text-decoration: underline;
  text-underline-offset: 5px; */
 border: 3px solid #D73B13;
 color: #D73B13;
  transition: ease-in-out 0.3s;
}
/* Hands Up */
.workMain{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}
.workBanner{
z-index: 1;

object-fit: cover;
height: 600px;
width: 100%;
}
.demoLink{
  font-family: 'Futura';
  display: flex;
  align-self: center;
  justify-content: center;
  transform: translateY(50px);
  font-size: 40px;
  border: 4px solid black;
  border-radius: 50px;
  text-decoration: none;
  color: black;
  padding: 10px 35px 10px 35px;
  transition: ease-in-out 0.3s;

}
.demoLink:hover{
  border: 4px solid #af7070;
  color: #af7070;
  transition: ease-in-out 0.3s;
}
.workContent{
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.sectionHUTitle{
  border-left: 5px solid #D69898;
  padding: 0% 0% 0% 5%;
  margin-top: 5%;
}

.sectionHUName{
color: #D69898;
font-family: 'Futura';
font-size: 24px;
font-stretch: condensed;
letter-spacing: -1px;
}
.sectionHupTitle{
  font-family: 'Futura';
  font-size: 36px;
}
.sectionHup1Title {
  font-family: 'Futura';
  font-size: 36px;
  display: flex;
 align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(-270px);
}
.sectionHup2Title {
  font-family: 'Futura';
  font-size: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(-300px);
}
.workP{
  font-family: 'Futura';
  color: black;
font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.5px;
  font-size: 19px;
}
.overviewHu{
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  border: 2px solid #BB7373;
  padding: 20px;
  background: rgba(187, 115, 115, 0.01);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    margin: 0px 0px 100px 0px;
}
.overviewTitle{
  color: #BB7373;
    font-size: 20px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.overviewSum{
  color: #000;
    font-size: 16px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
    transform: translateY(-30px);
}
.sectionA{
  align-self: flex-start;
  justify-content: flex-start;
}
.pinkTitle{
  color: #BB7373;
    font-size: 40px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.5px;
    display: flex;
      width: 868px;
      flex-direction: column;
      flex-shrink: 0;
      margin-top: 50px;
}
.discoverSection{
  background: #EAC4C4;
  width: max-content;
  align-items: center;
    justify-content: center;
    display: flex;
      flex-direction: column;
}
.pinkMain{
  display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    
}
.questionList{
  font-family: "Futura";
  color: #000;
    font-size: 24px;
   
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

}
.interviewCards{
  display: flex;

}
.intCard{
display: flex;
flex-direction: column;
/* border: 2px solid red; */
margin: 20px;
padding: 30px;
border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.intCardNum{
display: flex;
color: #af7070;
  font-size: 16px;
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  line-height: 17.5px;
  letter-spacing: 0.5px;
  align-self: flex-start;
  justify-content: flex-start;
  width: 90%;
  padding: 5px;
  border-bottom: 2px solid #af7070;
}
.intCardTitle{
 color: #AA5B5B;
    font-size: 36px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    width: 300px;
}
.intCardDes{
color: #000;
  font-size: 15px;
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  line-height: 113.313%;
  letter-spacing: 0.5px;
}
.flowHU{
  object-fit: cover;
  width: 900px;
}
.logoSection{
  display: flex;
}
.handsLogo{
  object-fit: cover;
  width: 500px;
}
.endUPButton{
  color: #BB7373;
    font-size: 40px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.5px;
}
.nextProject{
  text-decoration: none;
  font-family: 'Futura';
  color: black;
  font-size: 20px;
  padding: 10px 30px 10px 30px;
  border: 2px solid black;
  border-radius: 50px;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;}

.scrollNext{
  font-style: normal;
  text-decoration: underline;
}
.nextProject:hover{
  color: #AA5B5B;
  border: #AA5B5B 2px solid;
  border-radius: 50px;
  cursor: pointer;
}

/* /////////////SCROLL TROLL////////////////////////// */

.sectionSTTitle {
  border-left: 5px solid #7A9647;
  padding: 0% 0% 0% 5%;
  margin-top: 5%;
}

.sectionSTName {
  color: #7A9647;
  font-family: 'Futura';
  font-size: 24px;
  font-stretch: condensed;
  letter-spacing: -1px;
}

.overviewSt {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  border: 2px solid #89A557;
  padding: 20px;
  background: rgba(187, 115, 115, 0.01);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  margin: 0px 0px 100px 0px;
}

.overviewTitleSt {
  color: #89A557;
  font-size: 20px;
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.overviewSumSt {
  color: #000;
  font-size: 16px;
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  transform: translateY(-30px);
}